.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000066 0% 0% no-repeat padding-box;
}

.content {
  position: absolute;
  max-width: 600px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 100%;
  transform: translate(-50%, -50%);
  background: #ffffff 0% 0% no-repeat padding-box;
  z-index: 999;
}

.largeContent {
  position: absolute;
  max-width: 800px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 100%;
  transform: translate(-50%, -50%);
  background: #ffffff 0% 0% no-repeat padding-box;
  z-index: 999;
}

.mobileContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  z-index: 999;
}

.header {
  color: #000000de;
  width: 100%;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;

  @media screen and (min-width: 576px) {
    box-shadow: none;
    height: auto;
    padding-top: 30px;
  }
}

.closeIcon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);

  @media screen and (min-width: 576px) {
    left: auto;
    right: 20px;
    top: 30px;
    transform: translate(0, 0);
  }
}
