.container {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}

.logo {
  margin-top: 15px;
  height: 113px;
}

.title {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.01px;
  color: #ffffff;
  margin: 0;
  margin-top: 30px;
  @media screen and (min-width: 576px) {
    margin-top: 36px;
    font-size: 48px;
  }
}

.subTitle {
  text-align: center;
  max-width: 450px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 10px;
  @media screen and (min-width: 576px) {
    margin-top: 20px;
  }
}

.description {
  text-align: center;
  max-width: 450px;
  font-size: 16px;
  color: #ffffff;
  margin-top: 10px;
  letter-spacing: 0.5px;
  @media screen and (min-width: 576px) {
    margin-top: 20px;
  }
}

.help {
  margin-top: 16px;
  @media screen and (min-width: 576px) {
    margin-top: 35px;
  }
}

.tel {
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

.actions {
  width: 100%;
  max-width: 540px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 45px;
  & > button {
    border-radius: 28px;
    height: 56px;
    min-height: 56px;
    flex: 1;
    width: 100%;
  }
  @media screen and (min-width: 576px) {
    flex-direction: row;
  }
}
