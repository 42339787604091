.pageContainer {
  position: relative;
  margin-bottom: 10px;
  touch-action: pan-x pan-y;
  font-size: calc(15px + 0.19vw);
  @media screen and (min-width: 576px) {
    margin-bottom: 16px;
    font-size: calc(15px + 0.390625vw);
  }
}

.actions {
  padding: 20px 30px;
  width: 100%;
  max-width: 428px;
  width: 100%;
  margin: auto;
}
