.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
