.button {
  outline: none;
  border: none;
  cursor: pointer;
}

.block {
  letter-spacing: 0.36px;
  text-transform: uppercase;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  box-shadow: 5px 5px 10px #00000033;
  border-radius: 28px;

  @media screen and (min-width: 576px) {
    height: 56px;
  }
}
