.document {
  width: 100%;
  height: 100%;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  touch-action: pan-x pan-y;
}

.title {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 70px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.titleContent {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  touch-action: pan-x pan-y;

  & > span {
    font-size: 21px;
    font-weight: bold;
  }

  & > button {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.docContent {
  overflow-y: auto;
  padding: 0;
  padding-bottom: 30px;
  margin-top: 10px;
  height: calc(100% - 80px);
  background: #f9f9f9 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;

  &::-webkit-scrollbar {
    width: 3px;
    @media screen and (min-width: 576px) {
      width: 6px;
    }
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  & > div {
    max-width: 660px;
  }
}
