.container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 10px 20px 10px;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 10px 20px;
}

.mobileNote {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  display: flex;
  justify-content: center;
  column-gap: 8px;

  & > svg {
    width: 20px;
    height: 20px;
  }

  & > span {
    line-height: 20px;
    text-align: left;
  }
}

.note {
  font-size: 20px;
  text-align: center;
  letter-spacing: 0px;
  color: #707070;
  margin-top: -10px;
}

.actions {
  position: relative;
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 576px) {
    margin-top: 8px;
  }

  & > button {
    @media screen and (min-width: 576px) {
      max-width: 270px;
    }
  }
}

.mobileActions {
  position: relative;
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 576px) {
    margin-top: 8px;
  }

  & > button {
    @media screen and (min-width: 576px) {
      max-width: 368px;
    }
  }
}

.clear {
  position: absolute;
  bottom: calc(100% + 15px);
  right: 0;
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;

  & > svg {
    width: 16px;
  }

  @media screen and (min-width: 576px) {
    bottom: 50%;
    right: 0;
    transform: translate(0, 50%);
  }
}
