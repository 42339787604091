.mainContent {
  background-color: white;
  margin-top: 154px;
  border-radius: 30px 30px 0 0;
  height: calc(100% - 154px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.8);
  padding: 30px 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media screen and (min-width: 576px) {
    border: none;
    border-radius: 0;
    margin-top: 106px;
    height: calc(100% - 106px);
  }
  & > div:not(:last-child) {
    width: 100%;
    max-width: 388px;
  }
}

.logo {
  position: absolute;
  left: 50%;
  top: 11px;
  transform: translate(-50%, 0);
  height: 113px;
  @media screen and (min-width: 576px) {
    height: 80px;
  }
}

.title {
  color: #000000;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (min-width: 576px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.description {
  color: #000000;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.link {
  cursor: pointer;
  text-align: center;
  margin: auto;
  margin-top: 20px;

  &:hover {
    text-decoration: underline;
  }
}

.pdf {
  margin-top: 4px;
  height: 280px;
  position: relative;
  max-width: 200px !important;
  & > div {
    position: absolute;
    width: 100%;
    height: 260px;
    left: 0;
    top: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e3e3e3;
  }
}

.actions {
  margin: auto;
  width: 100%;
  max-width: 388px;
}

.footer {
  z-index: 1;
  background-color: white;
  flex: 1;
  width: 100%;
  padding-bottom: 30px;
}
