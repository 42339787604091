.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: transparent;
}

.modalContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 80px;
  display: block;
  @media screen and (min-width: 576px) {
    height: 113px;
  }
}

.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-top: 25px;
  @media screen and (min-width: 576px) {
    margin-top: 35px;
  }
}

.description {
  text-align: center;
  font-size: 16px;
  color: #000000;
  margin-top: 10px;
  letter-spacing: 0.5px;
}

.actions {
  width: 100%;
  max-width: 540px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 75px;
  & > button {
    border-radius: 28px;
    height: 56px;
    min-height: 56px;
    flex: 1;
    width: 100%;
  }
  @media screen and (min-width: 576px) {
    margin-top: 50px;
  }
}

.help {
  margin-top: 70px;
  @media screen and (min-width: 576px) {
    margin-top: 35px;
  }
}

.tel {
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 20px;
  @media screen and (min-width: 576px) {
    display: none;
  }
}
