body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

code {
  font-family: 'Open Sans', sans-serif;
}

* {
  box-sizing: border-box;
  touch-action: pan-x pan-y;
}

p {
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.signaturePad {
  width: 100%;
  aspect-ratio: initial;
}

html {
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif;
}

h2 {
  font-size: 38px;
  letter-spacing: -2px;
  margin: 30px 0;
  @media screen and (min-width: 576px) {
    font-size: 40px;
  }
}
