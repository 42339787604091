.container {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  border-radius: 4px;
  transform: translate(-15px, -50%);

  & > span {
    text-transform: uppercase;
    font-size: 14px;
    color: white;
    padding-right: 12px;
    font-weight: bold;
    letter-spacing: 0.01px;

    @media screen and (min-width: 576px) {
      font-size: 17px;
      padding-right: 20px;
    }
  }
}
